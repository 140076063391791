// 行动号召
const callToActionMap = {
  //   自动: 'null',
  立即申请: 'APPLY_NOW',
  立即预订: 'BUY_NOW',
  立即选购: 'SHOP_NOW',
  //   联系我们: 'CONTACT_US',
  //   下载: 'DOWNLOAD',
  //   了解更多: 'LEARN_MORE',
  //   访问网站: 'VISIT_SITE',
  //   注册: 'SIGN_UP',
  //   获取报价: 'GET_QUOTE',
  //   订阅: 'SUBSCRIBE',
  //   查看详情: 'SEE_MORE',
};

export const callToActionList = Object.keys(callToActionMap).map((key) => {
  return {
    key,
    value: callToActionMap[key],
  };
});

// 预算，出价类型
export const budgetTypes = [
  { label: '日预算', value: 'DAILY' },
  { label: '总预算', value: 'LIFE_TIME' },
];
export const bidStrategy = [
  { label: '自动竞价', value: 'AUTO' },
  { label: '手动出价', value: 'TARGET_CPA' },
];

export const statusList = [
  {
    label: '开启',
    value: 'ENABLED',
  },
  {
    label: '暂停',
    value: 'PAUSED',
  },
];
// 默认受众
export const getAudienceDefault = () => {
  return {
    groupCriterion: {
      target: {
        locations: [], // 地区
        languages: [], //语言
      },
      audience: {
        resourceName: null, //受众包
        ageRange: {
          minAge: 18,
          maxAge: null,
          undetermined: true,
        }, //年龄
        genders: ['UNDETERMINED', 'MALE', 'FEMALE'], // 性别
        languages: [], // 语言
        interests: [], // 兴趣词
        customAudiences: [], // 自定义受众
      },
    },
    optimizedTargetingEnabled: true, //优化型定位
    excludeDemographicExpansion: false, //仅向符合我的年龄和性别要求的用户展示广告
  };
};

export const getImgCreative = () => {
  // 默认图片广告
  return {
    type: 'DEMAND_GEN_MULTI_ASSET_AD', //广告类型
    multiImages: [], //素材资源
    logoImages: [], //1:1 (+-1%)徽标
    headlines: [], //标题
    descriptions: [], //描述
    businessName: '', //商家名称
    callToAction: { action: '自动' }, //行动号召, 图片广告专属
  };
};
export const getVideoCreative = () => {
  // 默认视频广告
  return {
    type: 'DEMAND_GEN_VIDEO_RESPONSIVE_AD', //广告类型
    logoImages: [], //1:1 (+-1%)徽标
    headlines: [], //标题
    longHeadlines: [], //长标题
    descriptions: [], //描述
    businessName: '', //商家名称
    videoCallToActions: [{ action: '自动' }], //行动号召, 图片广告专属
    videoAssets: [], //视频资源
  };
};
//弃用
function setAgeList(min, max) {
  let list = [];
  for (let i = min; i <= max; i++) {
    if (i == max) {
      list.push({ name: i + '+', value: i + '+' });
    } else {
      list.push({ name: i + '', value: i + '' });
    }
  }
  return list;
}
// 年纪列表
export const minAgeList = [
  { name: '18', key: 18, max: 24 },
  { name: '25', key: 25, max: 34 },
  { name: '35', key: 35, max: 44 },
  { name: '45', key: 45, max: 54 },
  { name: '55', key: 55, max: 64 },
  { name: '65+', key: 65, max: 100 },
];
export const maxAgeList = [
  { name: '24', key: 24, min: 18 },
  { name: '34', key: 34, min: 25 },
  { name: '44', key: 44, min: 35 },
  { name: '54', key: 54, min: 45 },
  { name: '64', key: 64, min: 55 },
  { name: '65+', key: 100 },
];
export const sexList = [
  { name: '男', key: 'MALE' },
  { name: '女', key: 'FEMALE' },
  { name: '未知', key: 'UNDETERMINED' },
];
//弃用
export const ageList = [
  {
    name: '18-24',
    key: 'AGE_RANGE_18_24',
  },
  {
    name: '25-34',
    key: 'AGE_RANGE_25_34',
  },
  {
    name: '35-44',
    key: 'AGE_RANGE_35_44',
  },
  {
    name: '45-54',
    key: 'AGE_RANGE_45_54',
  },
  {
    name: '55-64',
    key: 'AGE_RANGE_55_64',
  },
  {
    name: '65+',
    key: 'AGE_RANGE_65_UP',
  },
  {
    name: '未知',
    key: 'UNKNOWN',
  },
];

export const interestList = require('./json/interestTree.json');
export const regUrl =
  /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(\:[0-9]+)?)(\/[a-zA-Z0-9%_.~+-]+)*\/?(\?[\u4E00-\u9FA5\w%_.,~+-=&{}]*)?(#[\u4E00-\u9FA5\w_-{}]+)?$/;
//   获取网址的域名
export const extractDomain = (url) => {
  // 正则表达式用于匹配协议（如 http:// 或 https://）后的域名部分
  const regex = /^(?:https?:\/\/)?(?:www\.)?([^\/]+)/i;
  const match = url.match(regex);
  if (match && match[1]) {
    return match[1];
  }
  return null;
};
